import React from 'react';
import IndustriesCard from './ui/IndustriesCard';

const industriesContent = [
  {
    title: 'Semiconductors', 
    content: 'Reduce scrap and re-work of PCB with defect detection software that is compatible with your existing AOI systems.',
    imageUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/semiconductors-logo.png', 
    link: '/industries/semiconductors'
  },
  {
    title: 'Aerospace', 
    content: 'Use AI to automate defect detection during composite layup process or inspection of turbines in MRO facilities.',
    imageUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/aerospace-logo.png', 
    link: '/industries/aerospace'
  },
  {
    title: 'Medical Devices', 
    content: 'Inspection of small parts used for human surgery must be flawless. Give your inspectors AI-powered software for more precision.',
    imageUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/medical-devices-logo.png', 
    link: '/industries/medical-devices'
  },
  {
    title: 'Automotive', 
    content: 'Ensure manual assembly of parts is done to spec and automate inspection and re-inspections with traceable proof of record.',
    imageUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/automotive-logo', 
    link: '/industries/automotive'
  },
  {
    title: 'General Manufacturing', 
    content: 'Consistently inspect your manufacturing line for product defects using AI and get instant alerts to take remedial action',
    imageUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/general-manufacturing-logo', 
    // link: '/industries/general-manufacturing'
    link: '/'
  }, 
  {
    title: 'Mining', 
    content: 'Constantly monitor mine conditions for anomalies such as debris or infrastructure failure and receive instant alerts', 
    imageUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/mining-logo.png', 
    link: '/'
  }
]

const industriesContentHashMap = {
  'Semiconductors': 0, 
  'Aerospace': 1,
  'Medical Devices': 2,
  'Automotive': 3,
  'General Manufacturing': 4, 
  'Mining': 5
}

function getIndustriesData(industries){
  let data = []; 

  industries.forEach(industry => {
    if(industriesContentHashMap[industry]){
      data.push(industriesContent[industriesContentHashMap[industry]]);
    }
  })

  let maxContentLength = 0; 
  data.forEach(item => {
    if(item.content.length > maxContentLength){
      maxContentLength = item.content.length;
    }
  })

  return [data, maxContentLength];
}

export default function Industries({
  alignment = 'vertical', 
  title = true, 
  industries = []
}){
  let paddingTop;

  switch(alignment){
    case 'vertical': 
      paddingTop = 'pt-2 laptop-two:pt-7 ';
      break;
    case 'horizontal': 
      paddingTop = 'pt-0'
      break;
  }

  const cardsData = getIndustriesData(industries);

  return (
    <section className={`
      px-4 mobile-one:px-6 tablet:px-20
      ${paddingTop} 
      pb-7 laptop:pb-14 
    `}>
      {title && 
        <h2 className='
          text-mobile-heading-lg mobile-tablet-one:text-xl laptop:text-[2rem] 
          font-medium laptop:font-normal 
          mb-3 laptop:mb-6 
          pl-1
        '>
          Industries
        </h2>
      }

      <div className={`grid grid-cols-1 laptop-sm-one:grid-cols-3 gap-5`}>
        {cardsData[0]?.map((data, index) => {
          return( 
            <IndustriesCard
              key={`industries-card-${index}`}
              alignment={alignment}
              data={data}
              maxContentLength={cardsData[1]}
            />
          )
        })}
      </div>
    </section>
  )
}