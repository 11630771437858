import * as React from "react"
import { graphql } from 'gatsby'
import Page from './../components/Page'

import HeroSection from "../components/Home/HeroSection";
import Seo from "../components/Seo";
import Industries from "../components/Industries";
import BannerSection from "../components/BannerSection";
import HomeBottomWavy from "../components/Home/HomeBottomWavy";
import ProductsSection from "../components/Home/ProductsSection";
import HomeHeroWavy from "../components/Home/HomeHeroWavy";

const bannerImagesClasses = `
  w-[5rem] mobile-one:w-[6rem] mobile-lg-tablet:w-[7rem] laptop:w-[9rem]
`;

const IndexPage = (props) => {  
  const { attributes } = props.data.strapidata.homePage.data
  const { seo } = attributes
  return (
    <Page page="home" className="front">
      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        pimage={seo.shareImage.data.attributes.url}
        cpath = {props.location.pathname}
      />
      
      <div>
        <HeroSection />
        <HomeHeroWavy />
      </div>
      <BannerSection classes="mb-14 mt-12 laptop-one:mt-28 laptop-two:mt-40 laptop:my-24">
        <img src="https://looprdevstorage.blob.core.windows.net/website-temp/daimler-truck-logo" className={bannerImagesClasses} />
        <img src="https://looprdevstorage.blob.core.windows.net/website-temp/sandvik-logo" className={bannerImagesClasses} />
        <img src="https://looprdevstorage.blob.core.windows.net/website-temp/boeing-logo"  className={bannerImagesClasses} />
        <img src="https://looprdevstorage.blob.core.windows.net/website-temp/cascade-logo" className={bannerImagesClasses} />
        {/* <img src="https://looprdevstorage.blob.core.windows.net/website-temp/standard-logo" className={bannerImagesClasses} /> */}
      </BannerSection>
      <ProductsSection />
      <div className="relative overflow-hidden">
        <div>
          <Industries industries={[
            'Aerospace',
            'Automotive',
            'General Manufacturing', 
          ]}/>
        </div>
        <HomeBottomWavy />
      </div>
    </Page>
  )
}

export default IndexPage

export const query = graphql`
query HomePageQuery {
  strapidata {
    homePage {
      data {
        attributes {
          seo {
            metaTitle
            metaDescription
            shareImage {
              data {
                attributes {
                  url
                }
              }
            }
          }
          heroHeading
          heroDescription
          heroButtonText
          heroVideo {
            data {
              attributes {
                url
              }
            }
          }
          offerings_heading
          offerings_description
          offerings_items {
            offer_title
            offer_description
            offer_link
            offering_image{
              data{
                attributes{
                  url
                  width
                  height
                }
              }
            }
          }
          key_challenges_heading
          key_challenges_list {
            title
            description
            image {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
          }
          looprAdvantageHeading
          looprAdvantageContent
          looprAdvantageItems {
            looprAdvantagesItemContent
            looprAdvantagesItemHeading
            looprAdvantagesItemImage {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
          }
          breakingLabelsHeading
          breakingLabelsDescription
          breakingLabelsButtonText
          breakingLabelsButtonLink          
          useCasesHeading
          useCasesDescription
          useCaseItems {
            title : useCaseItemTitle
            description : useCaseItemDescription
            image : useCaseItemImage {
              data {
                attributes {
                  url
                  formats
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
}
`