import React from "react";
import { useContext } from "react";
import { MobileScreenContext } from "../Page";

export default function HomeHeroWavy() {
  const { mobileScreen } = useContext(MobileScreenContext);
  
  if(!mobileScreen){
    return (
      <div className="absolute top-0 right-0 -z-20">
        <img src="https://looprdevstorage.blob.core.windows.net/website-temp/home-hero-wavy-background" />
      </div>
    )
  }
  else {
    return (
      <div className="absolute top-0 -right-0 -z-10">
        <img src="https://looprdevstorage.blob.core.windows.net/website-temp/home-hero-wavy-background-mobile.png" />
      </div>
    )    
  }
}