import * as React from "react"
import { useContext } from "react"
import { MobileScreenContext } from "../Page"
import Button from "../ui/Button"
import Link from 'gatsby-link'

const data = {
	heading: [
		['Accelerate', 'Visual Inspection'], 
		['using', 'AI'],
	], 
	description: "Industry leaders use Loopr's AI software to improve efficiency of visual inspections, address workforce challenges, and ultimately reduce cost of quality."
}

export default function HeroSection (){
	const { mobileScreen } = useContext(MobileScreenContext);

	function displayHeadingText(heading){
		return heading.map(headingText => {
			return (
				<h1
					key={headingText} 
					className={`
						text-mobile-heading-lg mobile-one:text-mobile-heading-xl mobile-tablet-one:text-2xl mobile-tablet-two:text-3xl laptop-one:text-4xl laptop:text-5xl 
						font-semibold 
						laptop-small:mb-3 laptop:mb-5
						${mobileScreen ? "text-center" : "text-left"}
					`
				}>
					{headingText[0]}
					<span className="text-blue-800">
						{" " + headingText[1]}
					</span> 
				</h1>
			)
		})
	}

	if(!mobileScreen){
		return (
			<section className="
				flex 
				py-10
				tablet:px-10 laptop:px-20 
				relative
			">
				<div className="pb-5 w-full">
					{displayHeadingText(data.heading)}
					<h6 className="
						text-[0.8rem] laptop-sm-three:text-base laptop-one:text-lg laptop:text-xl 
						max-w-1/2 laptop:max-w-[45%]
						my-6 laptop:my-12
					">
						{data.description}
					</h6>
					<div className="
						w-36 laptop-sm-two:w-48 laptop:w-60 
						mb-4 laptop:mb-6
					">
						<Link to='/contact-us'>
							<Button type="primary">
								Book a demo
							</Button>
						</Link>
					</div>
					<div className="
						w-36 laptop-sm-two:w-48 laptop:w-60 
						mb-4 laptop:mb-6
					">
						<Link to='/onboarding'>
							<Button type="light">
								How we onboard
							</Button>
						</Link>
					</div>
				</div>
				<img 
					src="https://looprdevstorage.blob.core.windows.net/website-temp/home-hero-section" 
					className="
						block absolute -z-10 
						-top-36 laptop-sm-three:-top-24 laptop-one:-top-16 laptop-two:-top-10
						tablet:-right-[9.5rem] laptop-sm-three:-right-[6.5rem] laptop-one:-right-[3.5rem] laptop-two:-right-[1rem] laptop:right-0
						tablet:scale-[60%] laptop-sm-three:scale-[70%] laptop-one:scale-[85%] laptop-two:scale-[95%] laptop:scale-100
					"/>
			</section>
		)
	}
	else{
		return (
			<section className="
				px-5 mobile-tablet-one:px-20
				pt-7
			">
				<div className="flex flex-col">
					<div className="mb-4">
						{displayHeadingText(data.heading)}
					</div>
					<h6 className="
						mb-6 
						text-mobile-base mobile-one:text-mobile-heading-sm mobile-tablet-one:text-mobile-heading-md mobile-tablet-two:text-lg
						text-center
					">
						{data.description}
					</h6>
					<div className="flex flex-col items-center">
						<div className="
							w-36 mobile-tablet-one:w-44
							mb-4
						">
							<Link to='/contact-us'>
								<Button 
									type="primary"
									classes="mobile-tablet-one:text-mobile-heading-sm"
								>
									Book a demo
								</Button>
							</Link>
						</div>
						<div className="
							w-36 mobile-tablet-one:w-44
							mb-4
						">
							<Link to='/onboarding'>
								<Button 
									type="light"
									classes="mobile-tablet-one:text-mobile-heading-sm"
								>
									How we onboard
								</Button>
							</Link>
						</div>
					</div>
				</div>
			</section>
		)
	}
}