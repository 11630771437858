import React from "react";
import Button from "../ui/Button";
import UnderlinedTitle from "../ui/UnderlinedTitle";
import UpRightArrow from "../ui/UpRightArrow";
import { Link } from "gatsby";
import { useContext } from "react";
import { MobileScreenContext } from "../Page";

export default function ProductsSectionContent({ title, description, content, imageUrl, link}) {
  const { mobileScreen, screenWidth } = useContext(MobileScreenContext);

  return (
    <div className={`
      flex ${mobileScreen || (screenWidth < 1150) ? 'flex-col' : 'flex-row'}
      gap-5 tablet:gap-10 
      mb-7 tablet:mb-10 laptop:mb-14 
      overflow-hidden
    `}>
      <div className={`
        flex flex-col justify-between 
        laptop-one:pb-4
      `}>
        <div>
          <UnderlinedTitle 
            classes="
              mb-4 laptop:mb-7 
              ml-1
            " 
            text={title}
          />
          <div className="mb-2 tablet:mb-4">
            {description.map((descriptionText) => (
              <h4 
                key={`product-description-${descriptionText.split(" ")[0]}`}
                className="
                  font-semibold 
                  text-mobile-heading-sm mobile-tablet-one:text-mobile-heading-md tablet:text-mobile-heading-lg laptop:text-xl
              ">
                {descriptionText}
              </h4>
            ))}
          </div>
          <h4 className="
            text-mobile-base mobile-tablet-one:text-mobile-heading-sm tablet:text-mobile-heading-md laptop:text-xl 
            mb-3 tablet:mb-7
          ">
            {content} 
          </h4>
        </div>
        <div className="
          w-auto mobile-lg-tablet:w-24 mobile-tablet-one:w-32 laptop-small:w-36 
          ml-1.5
        ">
          <Link to={link}>
            <Button 
              type="light"
              classes="text-mobile-base mobile-tablet-one:text-mobile-heading-sm tablet:text-mobile-heading-md "
            >
              <div className="flex gap-x-4 items-center">
                <h4>Explore </h4>
                <UpRightArrow />
              </div>
            </Button>
          </Link>
        </div>
      </div>
      <img 
        src={imageUrl}
        className={`block rounded max-h-[378px]`}
      />
    </div>
  )
}