import React from 'react';

export default function UnderlinedTitle({children, classes = '', type = 'primary', text, underlineWidth = 'w-10/12'}){
  return (
    <div className={`inline-block ${classes}`}> 
      {text && 
        <h3 className="
          text-mobile-heading-md mobile-tablet-one:text-mobile-heading-lg tablet:text-mobile-heading-xl laptop:text-[1.75rem] 
          font-semibold 
          mb-2
        ">
          {text}
        </h3>
      }
      {children}
      <div className={`
        ${type === 'primary' ? 'bg-blue-800 shadow-sky' : 'bg-sky-400 shadow-white'}
        h-1 
        rounded 
        ${underlineWidth}
      `}></div>
    </div>
  )
}