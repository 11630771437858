import React from 'react';
import { Link } from 'gatsby';
import UpRightArrow from './UpRightArrow';
import Button from './Button';

export default function IndustriesCard({alignment, data, maxContentLength}){
    const {imageUrl, title, content, link} = data;

    function getTextContent() {
      let str = content; 

      if(str.length < maxContentLength){
        let diff = maxContentLength - str.length;
        for(let i = 0; i < diff; i++){
          str += ' ';
        }
      }
      
      return str;
    }

    let dimensions, alignmentClass, padding, text;
    
    switch(alignment){
      case 'vertical': 
        alignmentClass = 'flex flex-col'; 
        padding = 'p-5 laptop:p-8';
        text = getTextContent();
        break; 
      case 'horizontal': 
        alignmentClass = 'flex';
        dimensions = 'w-full mobile-tablet-one:w-[80%] mobile-tablet-two:w-[65%] laptop-sm-one:w-full mx-auto'; 
        padding = 'p-4 laptop:p-7'; 
        text = content.substring(0, 79).trim() + '...';
        break;
    }

  return (
    <div className={`
      flex flex-col 
      ${dimensions} 
      ${padding} 
      rounded-xl laptop:rounded-2xl 
      primary-gradient-border
    `}>
      <div className={`
        ${alignmentClass} 
        ${alignment === 'horizontal' && 'items-center'}
        h-full 
      `}>
        <div className='mb-2 laptop:mb-0'>
          <img 
            src={imageUrl} 
            alt={title} 
            className={
              alignment === 'horizontal' 
              ? `
                h-[2.06rem] laptop:min-h-[3.93rem] 
                w-[3.06rem] laptop-sm-one:w-[5.06rem] 
                laptop:min-w-[3.93rem]
              ` 
              : `
                h-[2.06rem] mobile-tablet-one:h-[2.56rem] laptop:h-[3.93rem]
              `
            }
          />
        </div>

        <div className={`
          flex flex-col justify-between 
          h-full 
          ${alignment === 'horizontal' && 'ml-5 laptop:ml-10'}
        `}>
          <div>
            <h2 className={`
              text-mobile-md laptop:text-2xl 
              font-bold 
              mb-1 laptop:mb-3 laptop:my-6 
              ${alignment === 'horizontal' && 'mt-0'}
            `}>
              {title}
            </h2>
            {alignment === 'vertical'? 
              <p className='
                mb-2 laptop:mb-3 
                text-mobile-base mobile-tablet-one:text-mobile-heading-sm laptop:text-base
              '>
                {text}
              </p>
              : 
              <h5 className='
                mb-2 laptop:mb-3 
                text-mobile-base laptop-sm-three:text-[0.75rem] laptop:text-base
              '>
                {text}
              </h5>
            }
          </div>
          
          <div className='
            w-auto mobile-lg:w-32 laptop-sm-three:w-36 laptop:w-52 
            mt-2
          '>
            <Link to={link}>
              <Button 
                type='light' 
                classes='
                  flex items-center 
                  gap-2 laptop:gap-4
                '>
                <h6 className='
                  text-blue-700 
                  font-bold 
                  text-mobile-base laptop-sm-three:text-mobile-heading-sm laptop:text-base
                '>
                  Know more   
                </h6>
                <UpRightArrow />
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}