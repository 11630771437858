import * as React from "react";
import ProductsSectionContent from "./ProductsSectionContent";

const data = [
  {
    title: 'LooprIQ Inspect',
    description: ['Inspect 100% of materials or', 'finished products'], 
    content: 'Reduce your cost of quality with AI-powered visual inspection that catches more defects instantly and consistently.', 
    imageUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/home-products-content-1', 
    link: '/products/loopriq-inspect'
  }, 
  {
    title: 'LooprIQ Verify',
    description: ['Ensure complex assembly', 'meets specifications, each time'], 
    content: 'Minimize part rework and recalls with AI-powered verification that instantly and objectively matches each assembled product with its spec sheet, directly on your assembly line.', 
    imageUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/home-products-content-2', 
    link: '/products/loopriq-verify'
  }, 
]

export default function ProductsSection () {
  return (
    <section className="px-4 mobile-one:px-6 mobile-lg-tablet:px-7 tablet:px-20">
      <h2 className="
        mb-3 tablet:mb-5 laptop:mb-10
        text-mobile-heading-lg mobile-tablet-one:text-xl laptop:text-[2rem]
      ">
        Products
      </h2>
      
      {data.map(item => (
        <ProductsSectionContent 
          key={`product-${item.title}`}
          title={item.title}
          description={item.description}
          content={item.content}
          imageUrl={item.imageUrl}
          link={item.link}
        />
      ))}
    </section>
  )
}