import React, { useContext } from 'react';
import { MobileScreenContext } from '../Page';

export default function HomeBottomWavy(){
    const { mobileScreen } = useContext(MobileScreenContext);

    if(!mobileScreen){
        return (
            <div className="absolute -z-10 -bottom-[33rem] large:-bottom-[24rem]">
                <img src="https://looprdevstorage.blob.core.windows.net/website-temp/home-bottom-wavy" />
            </div>
        )
    }
    else{
        return (
            <div className='absolute -bottom-80 left-0 -z-10'>
                <img src="https://looprdevstorage.blob.core.windows.net/website-temp/home-bottom-wavy-background-mobile.png" />
            </div>
        )
    }
}